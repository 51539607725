<template>
    <div
        v-click-outside="closePresetsList"
        class="presets_full"
    >
        <ul class="presets_full_list">
            <template v-for="(preset, index) in presets">
                <li
                    v-if="preset.counters.count"
                    :key="index"
                    class="presets_full-item"
                    :class="{ active: preset.value === selected }"
                    @click="setPreset(preset.value)"
                >
                    <span class="presets_full-item-title">
                        {{ preset.title }}
                        <span
                            class="presets_full-close-icon"
                            :class="{ close: preset.value === selected }"
                            @click.stop="setPreset(null)"
                        />
                    </span>
                    <span
                        class="presets_full-item-count"
                        :class="{ active: preset.value === selected }"
                    >
                        {{ preset.counters.count }}
                    </span>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'OrderPresetWindow',
    props: {
        presets: {
            type: Array,
            required: true,
            default: () => ([])
        },
        selected: {
            type: String,
            default: ''
        }
    },
    methods: {
        setPreset(value) {
            this.$emit('setPreset', value)
        },
        closePresetsList(e) {
            if (e.target.classList.contains('chevron-close')) return
            this.$emit('closePresetsList')
        }
    }
}
</script>

<style lang="scss">
.presets_full{
    background-color: #fff;
    position: absolute ;
    z-index: 20;
    top: 95px;
    right: 0;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 3px rgb(25, 50, 71, 25%);
    &_list{
        border-radius: 10px;
    }
    &-item{
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        cursor: pointer;
        &:hover{
            background-color: lightgrey;
        }
        &-count{
            font-weight: bold;
            color: #fff;
            background-color: $main-color;
            padding: 0px 7px;
            border-radius: 50px;
            font-style: normal;
            font-size: 14px;
            line-height: 20px;
            margin-left: 15px;
            min-width: 20px;
            height: 21px;
        }
        &-title{
            color: #435A7D;
            font-weight: 700;
            width: 60%;
        }
        &__presets-close-icon{
            width: 10%;
        }
    }
    &-item.active{
        background-color: #F2F6FF;
    }
    .close{
        &:after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-left: 7px;
            background: url("~@/assets/img/svg/close-dark-blue.svg") no-repeat center;
        }
    }
}
</style>
