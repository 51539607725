<template>
    <div class="preset-filter">
        <div class="preset-filter_statuses">
            <template v-for="(preset, index) in presets">
                <div
                    v-if="preset.counters.count"
                    :key="index"
                    class="preset-filter_status"
                    :class="{
                        active: preset.value === selected
                    }"
                    @click="setPreset(preset.value)"
                >
                    <span
                        class="preset-filter_status-title"
                        :class="{active: preset.value === selected}"
                    >
                        {{ preset.title }}</span>
                    <span
                        class="preset-filter_status__counter"
                        :class="{active: preset.value === selected}"
                    >
                        {{ preset.counters.count }}
                    </span>
                    <span
                        :class="{close : preset.value === selected }"
                        @click.stop="setPreset(null)"
                    />
                </div>
            </template>
        </div>
        <div class="preset-filter_menu">
            <span
                v-if="!loading"
                class="preset-filter_menu__btn"
                @click.stop="togglePresetsWindow"
            >
                <span
                    class="button-action-background"
                    :class="!isPresetWindowOpen ? 'chevron-open': 'chevron-close'"
                />
            </span>
            <transition name="open">
                <PresetFilterWindow
                    v-if="isPresetWindowOpen"
                    :presets="presets"
                    :selected="selected"
                    @closePresetsList="togglePresetsWindow"
                    @setPreset="setPreset"
                />
            </transition>
            <slot name="rightSide" />
        </div>
    </div>
</template>

<script>
import PresetFilterWindow from '@/components/common/PresetFilterWindow';

export default {
    name: 'PresetsFilter',
    components: {
        PresetFilterWindow
    },
    props: {
        presets: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        selected: {
            type: [String, null],
            default: ''
        }
    },
    data() {
        return {
            isPresetWindowOpen: false
        }
    },
    methods: {
        setPreset(preset) {
            if (preset !== this.selected) {
                this.$emit('setPreset', preset)
            }
        },
        togglePresetsWindow() {
            this.isPresetWindowOpen = !this.isPresetWindowOpen
        }
    }
}
</script>

<style lang="scss">
    .preset-filter {
        display: flex;
        align-items: center;
        background-color: #fff;
        box-shadow: 0 0 10px 3px rgb(25 50 71 / 25%);
        border-radius: 4px;
        padding: 20px 15px;
        position: relative;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            display: none;
        }

        &_loader{
            width: 40px;
            height: 40px;
            color: $main-color;
            svg {
                max-width: 100%;
            }
            &--order-list{
                width: 180px;
                svg {
                    margin: auto;
                }
            }
        }

        &_statuses {
            width: 80%;
            height: 45px;
            display: flex;
            padding-bottom: 3px;
            overflow: hidden;
            white-space: nowrap;
            position: relative;
            &:after{
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 60px;
                right: 0;
                z-index: 1;
                background: linear-gradient(270deg,#fff,hsla(0,0%,100%,.1));
            }
        }

        &_status {
            padding: 10px 5px 10px 15px;
            background: #fff;
            cursor: pointer;
            border: 3px solid transparent;
            border-radius: 6px;
            display: flex;
            align-items: center;
            position: relative;
            &__counter{
                font-weight: bold;
                color: #fff;
                background-color: $main-color;
                padding: 0px 7px;
                border-radius: 50px;
                font-style: normal;
                font-size: 14px;
                line-height: 20px;
                margin-left: 15px;
                min-width: 20px;
                height: 21px;
            }
            &.active {
                background-color: rgba($main-color, 0.1);
            }
        }
        &_status-title {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #435A7D;
        }
        &_status-title.active {
            color: $main-color;
        }
        &_menu{
            white-space: nowrap;
            display: flex;
            align-items: center;
            margin-left: 10px;
            &__btn{
                cursor: pointer;
            }
        }
    }

    .close{
        &:after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-left: 7px;
            background: url("~@/assets/img/svg/close-dark-blue.svg") no-repeat center;
        }
    }

    .chevron-close{
        &:after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            //margin-left: 7px;
            background: url("~@/assets/img/svg/close-dark-blue.svg") no-repeat center;
        }
    }

    .chevron-open{
        &:after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            //margin-left: 7px;
            background: url("~@/assets/img/svg/ellipsis.svg") no-repeat center;
        }
    }
    .open-enter,
    .open-leave-to {
        opacity: 0;
        transform: perspective(100px) rotateX(-15deg);
        transition: opacity .3s linear, transform 0.3s linear;
    }

    .open-enter-to,
    .open-leave {
        opacity: 1;
        transform: perspective(100px) rotateX(0);
        transition: opacity .3s linear, transform 0.3s linear;
    }
</style>
